import React from "react"
import Layout from "../../components/layout"
import { Container } from "../../components/layout/layoutComponents"
import SEO from "../../components/seo"
import PageHero from "../../components/PageHero"
import CardRow from '../../components/CardRow'
import SecondaryCardRow from '../../components/SecondaryCardRow'
import HcpHeader from "../../components/HcpHeader"
import Footer from "../../components/Footer"
import { ComponentWrapper } from "../../components/layout/ComponentWrapper"

const IndexPage = () => (
  <>
  <HcpHeader />
  <ComponentWrapper>
  <Layout>
    <Container>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <PageHero />
      <CardRow/>
      <SecondaryCardRow/>
    </Container>
  </Layout>
  </ComponentWrapper>
  <Footer/>
  </>
)

export default IndexPage
