import React from "react"
import styled from "styled-components"
import background from "../../assets/hcp-desktop-banner.jpg";
import MobileBackground from "../../assets/hcp-mobile-banner.jpg";
import Icon1 from "../../assets/hcp-desktop-hero-benefit.png";
import Icon2 from "../../assets/hcp-desktop-hero-copay.png";
import Icon3 from "../../assets/hcp-desktop-hero-referral.png";
import Icon4 from "../../assets/hcp-desktop-hero-billing.png";
import MobileIcon1 from "../../assets/hcp-mobile-hero-benefit.png";
import MobileIcon2 from "../../assets/hcp-mobile-hero-copay.png";
import MobileIcon3 from "../../assets/hcp-mobile-hero-referral.png";
import MobileIcon4 from "../../assets/hcp-mobile-hero-billing.png";
import WhiteArrow from '../../assets/white-arrow.png';
import { Link } from 'gatsby';

const HeroContainer = styled.div`
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    height: 436px;
    width: calc(100% + 30px);
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    transform: translateX(-15px);
    -webkit-transform: translateX(-15px);

    @media (max-width: 415px) {
        background: none;
        height: 100%;
    }
`;

const MobileHero = styled.div`
    display: none;

    @media (max-width: 415px) {
        display: block;
        background-image: url(${MobileBackground});
        background-repeat: no-repeat;
        background-size: contain;
        width: calc(100% + 30px);
        transform: translateX(-15px);
        -webkit-transform: translateX(-15px);
        height: 162px;
    }
`

const ComponentWrapper = styled.div`
    padding: 14px 0 0 14px;
`;

const TagLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: flex-start;

    h1 {
        color: var(--white);
        font-weight: 600;
        margin-bottom: 1.4rem;

        @media (max-width: 415px) {
            color: var(--black);

            br {
                display: none;
            }
        }
    }
`;

const QuestionContainer = styled.div`
    padding-left: 65px;

    @media (max-width: 415px) {
        padding-left: 60px;
        padding-right: 15px;
    }
`;

const QuestionItemOne = styled.div`
    display: flex;
    align-items: center;
    height: 54px;
    color: var(--white);
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 6px;
    position: relative;

    p:before {
        content: '';
        background-image: url(${Icon1});
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 39px;
        position: absolute;
        top: 8px;
        left: -3.5rem;

        @media (max-width: 415px) {
            background-image: url(${MobileIcon1});
        }
    }
`;

const QuestionItemTwo = styled.div`
    display: flex;
    align-items: center;
    height: 54px;
    color: var(--white);
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 9px;
    position: relative;

    p:before {
        content: '';
        background-image: url(${Icon2});
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 38px;
        position: absolute;
        top: 7px;
        left: -3rem;

        @media (max-width: 415px) {
            background-image: url(${MobileIcon2});
        }
    }
`;

const QuestionItemThree = styled.div`
    display: flex;
    align-items: center;
    height: 70px;
    color: var(--white);
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 9px;
    position: relative;

    p:before {
        content: '';
        background-image: url(${Icon3});
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 38px;
        position: absolute;
        top: 17px;
        left: -3.7rem;

        @media (max-width: 415px) {
            background-image: url(${MobileIcon3});
            height: 34px;
        }
    }
`;

const QuestionItemFour = styled.div`
    display: flex;
    align-items: center;
    height: 53px;
    color: var(--white);
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 9px;
    position: relative;

    p:before {
        content: '';
        background-image: url(${Icon4});
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 39px;
        position: absolute;
        top: 6px;
        left: -3rem;

        @media (max-width: 415px) {
            background-image: url(${MobileIcon4});
        }
    }
`;

const Detail = styled.p`
    margin-bottom: 0;
    padding-left: 8px;
    font-weight: 500;

    @media (max-width: 415px) {
        color: var(--black);
        font-size: 12px;
        line-height: 14px;
        br {
            display: none;
        }
    }
`;

const HeroCTAText = styled.div`
    color: var(--white);

    @media (max-width: 415px) {
        color: var(--black);
        max-width: 60%;
    }

    > h1 {
        color: inherit;
        margin-bottom: 5px;
        font-weight: 600;

        @media (max-width: 415px) {
            font-size: 12px;
        }
    }

    > p {
        font-size: 16px;
        line-height: 1.4;
        color: var(--white);

        @media (max-width: 415px) {
            font-size: 12px;
            line-height: 14px;
            color: var(--black);

            br {
                display: none;
            }
        }

        @media (max-width: 775px) {
            line-height: 1.2;
        }
    }
`;

const CTAContainer = styled.div`
    display: flex;
`;

const Button = styled.div`
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: var(--white);
    background: var(--light-blue);
    height: 100%;
    font-weight: bold;
    padding: 7px 50px 7px 12px;
    border-radius: 4px;
    margin: 20px 35px 0px;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
    position: relative;

    @media (max-width: 320px) {
        font-size: 9px !important;
    }

    @media (max-width: 415px) {
        margin: 20px 8px 0px !important;
        font-size: 12px;
        padding: 0 30px 0 8px;
    }

    @media (max-width: 775px) {
        margin: 20px 20px 0px;
    }

    a {
        color: var(--white);
        &:after {
            content: '';
            background-image: url(${WhiteArrow});
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 24px;
            position: absolute;
            top: 8px;
            left: 87%;
            z-index: 1;

            @media (max-width: 415px) {
                height: 15px;
                left: 88%;
                top: 4px;
            }
        }
    }

    :after {
        content: '';
        background-color: var(--lighter-blue);
        position: absolute;
        width: 40px;
        margin: -7px 10px 4px 14px;
        height: 40px;
        border-radius: 4px;

        @media (max-width: 415px) {
            height: 23px;
            width: 24px;
            margin: 0px 0px 4px 8px;
        }
    }
`;

const PageHero = () => (
    <>
    <MobileHero/>
    <HeroContainer>
        <ComponentWrapper>
            <TagLine>
                <h1>
                    The Organon Access Program may be able to help
                    <br/> answer questions about access and support, including:
                </h1>
            </TagLine>
            <QuestionContainer>
                <QuestionItemOne>
                    <Detail>Benefit investigations and information about
                    <br/> prior authorizations and appeals</Detail>
                </QuestionItemOne>
                <QuestionItemTwo>
                    <Detail>Co-pay assistance for eligible patients</Detail>
                </QuestionItemTwo>
                <QuestionItemThree>
                    <Detail>Referral to the Organon Patient Assistance Program
                    <br/> for eligibility determination (provided through the 
                    <br/> Organon Patient Assistance Program Inc.)
                    </Detail>
                </QuestionItemThree>
                <QuestionItemFour>
                    <Detail>Billing and coding</Detail>
                </QuestionItemFour>
            </QuestionContainer>
            <CTAContainer>
                <HeroCTAText> {/*  textblock for getstarted */}
                    <h1>Get Started</h1>
                    <p>
                        To learn more about this program, please click on the
                        <br/>“Learn More” button or the logo of your medication below.
                    </p>
                </HeroCTAText>
                <Button><Link to="/hcp/organon-access-program/">LEARN MORE</Link></Button>
            </CTAContainer>
        </ComponentWrapper>
    </HeroContainer>
    </>
);

export default PageHero;