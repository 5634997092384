import React from "react"
import styled from "styled-components"

import OntruzantLogo from '../../assets/ontruzant-logo.svg';
import RenflexisLogo from '../../assets/renflexis-logo.svg';
import ListPipe from '../../assets/list-pipe.png';
import WhiteArrow from '../../assets/white-arrow.png';
import PageDivider from "../PageDivider"

const CardRow = () => (
    <>
    <CardWrapper>
    <h1>Click on the Medication Below for Information or to Enroll:</h1>
        <CardContainer>
            <Card>
                <CardContent>
                    <LogoContainer>
                        <OntruzantLogo/>

                        <Button>
                            <a target="_blank" href="https://www.organonaccessprogram-ontruzant.com/hcp/">LEARN MORE</a>
                        </Button>
                    </LogoContainer>

                    <CardText>
                        Before prescribing ONTRUZANT, please read the accompanying <a target="_blank" href="https://www.organon.com/product/usa/pi_circulars/o/ontruzant/Ontruzant-pi.pdf">Prescribing&nbsp;Information</a>, including the Boxed Warning about cardiomyopathy, infusion reactions (pulmonary toxicity), and embryo-fetal toxicity.
                    </CardText>
                </CardContent>

                <LinkContainer>
                    <LinkText><a target="_blank" href="https://www.organon.com/product/usa/pi_circulars/o/ontruzant/Ontruzant-pi.pdf">PRESCRIBING INFORMATION</a></LinkText>
                </LinkContainer>
            </Card>

            <Card>
                <CardContent>
                    <LogoContainer>
                        <RenflexisLogo/>

                        <Button>
                            <a target="_blank" href="https://www.organonaccessprogram-renflexis.com/hcp/">LEARN MORE</a>
                        </Button>
                    </LogoContainer>

                    <CardText>
                        Before prescribing RENFLEXIS, please read the accompanying <a target="_blank" href="https://www.organon.com/product/usa/pi_circulars/r/renflexis/renflexis-pi.pdf">Prescribing&nbsp;Information</a>, including the Boxed Warning about serious infections and malignancies. The <a target="_blank" href="https://www.organon.com/product/usa/pi_circulars/r/renflexis/renflexis-mg.pdf">Medication Guide</a> also is available.
                    </CardText>
                </CardContent>

                <LinkContainer>
                    <LinkText><a target="_blank" href="https://www.organon.com/product/usa/pi_circulars/r/renflexis/renflexis-pi.pdf">PRESCRIBING INFORMATION</a><span></span></LinkText>

                    <LinkText><a target="_blank" href="https://www.organon.com/product/usa/pi_circulars/r/renflexis/renflexis-mg.pdf">MEDICATION GUIDE</a></LinkText>
                </LinkContainer>
            </Card>
        </CardContainer>
    </CardWrapper>

    <PageDivider/>
    </>
)

const CardWrapper = styled.div`
    width: 100%;
    padding: 1em 0;

    h1 {
        margin-bottom: 1rem;
    }
`

const CardContainer = styled.div`
    display: flex;
    margin: -15px;
    @media (max-width: 415px) {
        flex-direction: column;
    }
`

const Card = styled.div`
    margin: 15px;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
`

const CardContent = styled.div`
    padding: 10px 15px 5px;
`

const LogoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    margin-bottom: 0.5rem;

    svg {
        width: auto;
        height: 100%;

        @media (max-width: 415px) {
            max-width: 50%;
        }

        @media (max-width: 775px) {
            height: 75%;
        }
    }
`
const CardText = styled.div`
    font-family: Montserrat;
    font-size: 10px;
    line-height: 12px;

    a {
        font-weight: bold;
        text-decoration: underline;
    }
`
const Button = styled.div`
    font-family: Arial;
    background: var(--dark-blue);
    color: var(--white);
    padding: 0 30px 0 8px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 4px;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
    cursor: pointer;
    position: relative;

    a {
        color: var(--white);

        &:after {
            content: '';
            background-image: url(${WhiteArrow});
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 18px;
            position: absolute;
            top: 4px;
            left: 87%;
            z-index: 1;

            @media (max-width: 415px) {
                height: 15px;
                left: 88%;
            }
        }
    }

    :after {
        content: '';
        background-color: var(--pink);
        position: absolute;
        width: 24px;
        margin-left: 8px;
        height: 26px;
        border-radius: 4px;

        @media (max-width: 415px) {
            height: 23px;
        }
    }
`

const LinkContainer = styled.div`
    height: 30px;
    background: var(--light-gray);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`

const LinkText = styled.div`
    font-family: Montserrat;
    font-size: 10px;
    position: relative;

    a { 
        color: var(--black);
    }

    span {
        &:after {
            content: '';
            background-image: url(${ListPipe});
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 23px;
            position: absolute;
            top: 2px;
            left: 175px;

            @media (max-width: 775px) {
                top: 0;
                left: 161px;
            }
        }
    }
`

export default CardRow